import React from 'react'
import { Link, graphql } from 'gatsby'
import { getImage } from "gatsby-plugin-image"
import { BgImage } from "gbimage-bridge"

//fontawsome icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTwitter,
  faLinkedinIn,
  faFacebookF,
} from "@fortawesome/free-brands-svg-icons";

//components
import Layout from "../../components/layout";
import Seo from "../../components/seo";
import StayLoop from "../../components/Home/stayloop";
import MoreArticles from "../../components/Home/morearticles";

const BlogSingle = (props) => {
  const { blog: post } = props.data
  const SEOdata = props.data.awardpagedata.edges[0].node.frontmatter?.seo_metadata
  const Schemadata = props.data.awardpagedata.edges[0].node.frontmatter?.Schema
  const Hreflang = props.data.awardpagedata.edges[0].node.frontmatter?.Hreflang
  const { edges: morepost } = props.data.moreArticles
  return (
    <Layout>
      <Seo
        title={SEOdata ? SEOdata.seo_title : "The Power of Teamwork for Increased Productivity"}
        description={SEOdata ? SEOdata.seo_description : ""}
        keywords={SEOdata ? SEOdata.seo_keyword : ""}
        image={SEOdata ? SEOdata.seo_Image?.childImageSharp?.gatsbyImageData?.images?.fallback?.src : ''}
        single_image={SEOdata ? SEOdata.seo_Single_Image : ''}
        currentPageUrlPath={props.location.pathname}
        image_format={SEOdata ? SEOdata.image_format : ""}
        image_height={SEOdata ? SEOdata.feature_image_height : ""}
        img_width={SEOdata ? SEOdata.img_width : ""}
        facebook_url={SEOdata ? SEOdata.facebook_url : ""}
        twitter_page_username={SEOdata ? SEOdata.twitter_page_username : ""}
        twitter_image_alt_tag={SEOdata ? SEOdata.twitter_image_alt_tag : ""}
        twitter_estimated_reading_lable={SEOdata ? SEOdata.twitter_estimated_reading_lable : ""}
        twitter_estimated_reading_time={SEOdata ? SEOdata.twitter_estimated_reading_time : ""}
        page_publish_date={SEOdata ? SEOdata.page_publish_date : ""}
        page_last_modified_time={SEOdata ? SEOdata.page_last_modified_time : ""}
        sitelinks_schema={Schemadata ? Schemadata.sitelinks_schema : ""}
        breadcrumb_schema={Schemadata ? Schemadata.breadcrumb_schema : ""}
        logo_schema={Schemadata ? Schemadata.logo_schema : ""}
        organization_schema={Schemadata ? Schemadata.organization_schema : ""}
        aggregate_rating_schema={Schemadata ? Schemadata.aggregate_rating_schema : ""}
        local_business_schema={Schemadata ? Schemadata.local_business_schema : ""}
        site_navigation_schema={Schemadata ? Schemadata.site_navigation_schema : ""}
        speakable_specification_schema={Schemadata ? Schemadata.speakable_specification_schema : ""}
        hreflang1={Hreflang ? Hreflang.hreflang_1 : ""}
        hreflang2={Hreflang ? Hreflang.hreflang_2 : ""}
        hreflang3={Hreflang ? Hreflang.hreflang_3 : ""}
        hreflang4={Hreflang ? Hreflang.hreflang_4 : ""}
      />


      {/* blog description section */}

      <section className="mt-60 lg:mt-20 pt-6-6 pb-35 lg:pt-51 lg:pb-59">
        <div className="container mx-auto">
          <div className="flex flex-wrap justify-between">
            <div className="hidden xl:block w-full lg:w-1/6"></div>
            <div className="w-full lg:w-4/5 xl:w-2/3">
              <h2 className="mb-3-3 lg:mb-1-3 text-rh2 sm:text-h3 lg:text-h2 font-poppings-bold text-term-primary">{post.frontmatter.title}</h2>
              <div className="blog-inner--cnt text-p4 lg:text-p2 font-worksans-normal text-term-primary"><p>Did you know 75% of employers believe teamwork and collaboration are essential to the job? Did you know that
                those are the same employers who don’t make sure employees are working efficiently together? Teamwork
                support is essential in the workplace and is known to enhance productivity, which is key to success. At DecodeUp, our associates are not simply employees to us, they all strive to learn and work together as a team. And the results are clear in our customer satisfaction. Keep reading for our guide on why teamwork support is essential in the
                workplace and how you can help your company to work together.</p>
                <h3>Establish Good Communication</h3>
                <p>One of the many ways you can build teamwork in your office is by establishing open communication. Within our work culture, we like to communicate openly and freely with each other no matter what our position is in the company. When you communicate transparently with your associates, you open doors for smooth, efficient daily operations. You can set an example for your team by practicing good communication with clients and internally. A great way to start open communication with your team is asking them how they like to receive feedback, both negative and positive. Some people prefer feedback in private through a face-to face meeting, or written. As a manager, you should respect that. Also promote communication by having frequent team meetings. Meetings are an essential part of our day at DecodeUp, which is why our team members are always willing to talk when issues arise.</p>
                <h3>Incorporate Team-Building Exercises</h3>
                <p>A great way to promote teamwork is frequently using team building exercises. This is an excellent way to get to know one another and a fun way to learn how to work together. Most employees find team building boring or useless, but there are so many fun exercises that aren't cheesy. At DecodeUp, we've found exercises that build our associates into leaders and even give back to the community. You can find some that fit the needs of your workplace.</p>
                <p>For example, do a quick exercise before a team meeting to make it more fun. Or have a game the second Saturday of every month. Recently we started doing two minutes of timed meditation before important meetings to clear our space and get into the new zone.</p>
                <h3>Create a Positive Environment</h3>
                <p>Another reason our associates work so hard is because of the good office environment we've worked to create. If
                  you're having trouble motivating your team, then use your communication skills and ask each team member what
                  would make their environment more comfortable. Sometimes lack of space in the office can make people uncomfortable.</p>
                <p>Of course, we think open work environments will automatically promote productivity, but sometimes people may feel like they're on top of each other and can’t think. We like to ask our team to find points of improvements around the office. This is a good way for associates to feel motivated and connected throughout the day. Each person has a say in the atmosphere. Work culture and feeling comfortable at work are great ways to enhance productivity and we look to achieve that at DecodeUp for each of our associates.</p>
                <h3>Use Feedback The Right Way</h3>
                <p>As we've mentioned before, feedback is useful and important when communicating with your team and promoting teamwork in the workplace. During evaluations we look for ways our associations can work together as a team. We've noticed that giving the right kind of feedback is a great way to help them excel. Pushing them when they need to be pushed is great, but you need to do it the right way. Pointing out when you can implement teamwork is an excellent way to motivate people. We also like to make sure
                  that we're pointing out positives and what they excel at individually. We also make a point to mention when they work as a team. This is the best way to recognize them for the wonderful things they do.</p>
                <h3>Establish a Family Atmosphere</h3>
                <p>We want each associate to feel like they're a part of our family at DecodeUp, not that they just work here, which is
                  key to teamwork support. Creating a family atmosphere means being conversational and supporting our team members inside and outside of work.</p>
                <p>Another way we promote a family atmosphere is ensuring our associates know that as managers, we have their backs just like a family member would. This is an excellent stance for a manager because your team members won't fear you or their job. Luckily for DecodeUp, we're skilled at hiring people who genuinely care about each other. This is a great stepping stone to creating that atmosphere.</p>
                <h3>Teamwork Support in the Office</h3>
                <p>When it comes to teamwork support in the office, there are plenty of things that we do to help our team improve every day. Think about ways you can help your team improve. We love that our team can work together efficiently, and it shows when the productivity and motivation in our workplace are at an all-time high.</p>
                <p>Maybe you deal with productivity or maybe your team is service-based. Think of how you can improve the work and the culture by collaborating.</p>
                <h3>Conclusion</h3>
                <p>Teamwork is the best way to build success. We strongly believe in teamwork and our work proves that it’s a great philosophy. We will be glad to have you join our team to continue to find success.</p></div>
            </div>
            <div className="w-full lg:w-1/6">
              <div className="w-full lg:w-3/4 text-left lg:text-right flex flex-wrap lg:flex-col justify-between">
                <div className="lg:mb-39">
                  <h5 className="lg:mb-1-1 uppercase text-cyan font-poppings-bold text-rh6 md:text-h6">
                    {post.frontmatter.blog_category}
                  </h5>
                  <p className="text-term-primary font-worksans-normal text-p4 lg:text-p3">
                    {post.frontmatter.date}
                  </p>
                </div>
                <div className="flex items-center lg:items-end lg:flex-col ">
                  <Link to={post.frontmatter.twitter} target="_blank" className="text-right opacity-20 hover:opacity-100 transform transition duration-500 ease">
                    <FontAwesomeIcon
                      className="font-awesome text-2xl text-term-primary "
                      icon={faTwitter}
                    />
                  </Link>
                  <Link to={post.frontmatter.linkedin} target="_blank" className="text-right opacity-20 hover:opacity-100 transform transition duration-500 ease">
                    <FontAwesomeIcon
                      className="mx-8 lg:mx-0 lg:my-5-1 font-awesome text-2xl text-term-primary "
                      icon={faLinkedinIn}
                    />
                  </Link>
                  <Link to={post.frontmatter.facebook} target="_blank" className="text-right opacity-20 hover:opacity-100 transform transition duration-500 ease">
                    <FontAwesomeIcon
                      className="font-awesome text-2xl text-term-primary"
                      icon={faFacebookF}
                    />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* more articles section */}
      {morepost.length &&
        <section className="pb-50 lg:pb-1-04">
          <MoreArticles articles={morepost} />
        </section>
      }

      {/* stay in loop section */}
      <StayLoop />

    </Layout>

  )
}

export default BlogSingle

export const BlogPageQuery = graphql`
  query BlogPage9($id: String!) {
    blog: markdownRemark(id: { eq: $id }) {
      id
      frontmatter {
        title
        blog_category
        templateKey
        facebook
        linkedin
        twitter
        date(formatString: "DD MMM, YYYY")
        
      }
    }
    moreArticles: allMarkdownRemark(
      filter: { 
              id: { ne: $id },
              frontmatter: { templateKey: { eq: "single-blog" } }
            }
      ) {
       edges {
         node {
           id
           fields {
             slug
           }
           frontmatter {
             title
             blog_category
             templateKey
             featured_image {              
              image {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: BLURRED
                  )
                }
              }
            }
             date(formatString: "DD MMM, YYYY")
           }
         }
       }
     }
     awardpagedata: allMarkdownRemark(filter: {id: {eq: $id}}) {
      edges {
        node {
          frontmatter {
            Hreflang {
              hreflang_1
              hreflang_3
              hreflang_2
              hreflang_4
            }
            Schema {
              aggregate_rating_schema
              breadcrumb_schema
              local_business_schema
              logo_schema
              organization_schema
              site_navigation_schema
              sitelinks_schema
              speakable_specification_schema
            }
            seo_metadata {
              facebook_url
              feature_image_height
              image_format
              img_width
              page_last_modified_time
              page_publish_date
              seo_description
              seo_keyword
              seo_title
              twitter_estimated_reading_lable
              twitter_estimated_reading_time
              twitter_image_alt_tag
              twitter_page_username
              seo_Image {
                childImageSharp {
                  gatsbyImageData(layout: FIXED, width: 1200)
                }
              }
              seo_Single_Image
            }
          }
        }
      }
    }
  }`